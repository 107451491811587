/**
 * splitPathArray: Takes in a route string, converts to Array of titles and their root paths as relative urls.
 * @param linkPath - The route as string
 * @returns - Returns an Array of titles and their root paths as relative urls.
 */
export const splitPathArray = (linkPath: string, pathname: string) => {
    const splitLinkPath = linkPath.split('/').filter((path) => !path.substring(0, path.indexOf('#')));
    const splitPathName = pathname.split('/');
    splitLinkPath.shift();
    splitPathName.shift();
    const linkPathArray = splitLinkPath.map((path, i) => {
        return {
            title: path,
            href: `/${splitLinkPath?.slice(0, i + 1).join('/')}`,
            route: `/${splitPathName?.slice(0, i + 1).join('/')}`
        };
    });

    return linkPathArray;
};

/**
 * decodePath: Takes in a route string, decodes path readable title
 * @param title - The route title to decode
 * @returns - Returns a clean readable title as string
 */
export const decodePath = (urlPath: string) => {
    const newString: string =
        urlPath
            ?.replace(/-/g, ' ')
            .split(' ')
            .map((word) => word[0]?.toUpperCase() + word?.slice(1))
            .join(' ') || '';

    return decodeURI(newString);
};

/**
 * slugify a string
 * @param {str} string
 * @returns url friendly string
 */
export const slugify = (string_: string) => {
    let slug = string_.replace(/^\s+|\s+$/g, '').toLocaleLowerCase();

    // Remove accents, swap ñ for n, etc
    const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;';
    const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------';
    for (let index = 0, l = from.length; index < l; index++) {
        slug = string_.replace(new RegExp(from.charAt(index), 'g'), to.charAt(index));
    }

    // Remove invalid chars
    slug = slug
        .replace(/[^\d a-z-]/g, '')
        // Collapse whitespace and replace by -
        .replace(/\s+/g, '-')
        // Collapse dashes
        .replace(/-+/g, '-');

    return slug;
};

import React from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';
import styled from 'styled-components';

import facebook_svg from '~/assets/img/social/facebook.svg';
import instagram_svg from '~/assets/img/social/instagram.svg';
import linkedin_svg from '~/assets/img/social/linkedin.svg';
import twitter_svg from '~/assets/img/social/twitter.svg';

const links = [
    {
        icon: twitter_svg,
        href: 'https://twitter.com/Vinovest1',
        name: 'twitter'
    },
    {
        icon: facebook_svg,
        href: 'https://www.facebook.com/vinovestofficial/',
        name: 'facebook'
    },
    {
        icon: instagram_svg,
        href: 'https://www.instagram.com/vinovest_official/',
        name: 'instagram'
    },
    {
        icon: linkedin_svg,
        href: 'https://www.linkedin.com/company/vinovest',
        name: 'linkedin'
    }
];

const StyledList = styled.ul`
    display: flex;
    list-style: none;
    margin: 0;
    align-items: center;
    padding: 10px 0;
    align-self: center;
    font-family: var(--font-favoritstd);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;

    @media screen and (max-width: 1024px) {
        padding: 0;
        font-size: 14px;
        line-height: 28px;
    }

    @media screen and (max-width: 767px) {
        padding: 0;
        margin-top: 38px;
    }

    li {
        display: flex;
        align-items: flex-end;
        margin-right: 20px;

        a {
            display: flex;
            align-items: flex-end;
        }

        img {
            width: 18px;
            height: 18px;
            object-fit: contain;
        }
    }
`;

const FooterSocial = () => {
    return (
        <StyledList>
            <li className={'text-right'}>
                <span>Follow</span>
            </li>
            {links.map((link) => (
                <li key={link.name}>
                    <Link href={link.href} rel={'noopener noreferrer'} target={'_blank'}>
                        <Image src={link.icon} width={'30'} height={'30'} alt={link.name} />
                    </Link>
                </li>
            ))}
        </StyledList>
    );
};

export default FooterSocial;
